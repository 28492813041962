<template>
    <v-row class="ma-n4">
        <v-col cols="12" sm="6">
            <v-card outlined class="pa-3">
                <HorizontalCircularProgress color="green" size="100" :value="value.progreso_total * 100">
                    <div>
                        <span class="d-block headline font-weight-regular">Progreso general</span>
                        <span class="d-block subtitle-2">Basado en el progreso de cada participante {{ view == 'empresa' ? 'que pertenezca a la empresa' : 'que trabaje con la gerencia' }}</span>
                    </div>
                </HorizontalCircularProgress>
            </v-card>
        </v-col>
        <v-col cols="12" sm="6">
            <v-card outlined class="pa-3">
                <HorizontalCircularProgress color="primary" size="100" :value="value.total_rel * 100">
                    <div>
                        <span class="d-block subtitle-2 font-weight-bold">Presencia</span>
                        <span class="d-block headline font-weight-regular">
                            <template v-if="value.total > 1">{{ value.total }} participantes</template>
                            <template v-else-if="value.total == 1">{{ value.total }} participante</template>
                            <template v-else-if="value.total < 1">Sin participantes</template>
                        </span>
                        <span class="d-block subtitle-2">
                            <template v-if="value.total > 1">
                                Subconjunto de participantes que {{ view == 'empresa' ? 'pertenecen a la empresa seleccionada' : 'trabajan con la gerencia seleccionada' }}, del total de {{ value.total_global }} participantes.
                            </template>
                            <template v-else>
                                Ninguno de los {{ value.total_global }} participantes {{ view == 'empresa' ? 'pertenecen a la empresa seleccionada' : 'trabajan con la gerencia seleccionada' }}.
                            </template>
                        </span>
                    </div>
                </HorizontalCircularProgress>
            </v-card>
        </v-col>
        <v-col cols="12">
            <v-card outlined>
                <div class="d-block text-right pa-2">
                <v-radio-group hide-details class="d-inline-block pa-0 ma-0" row v-model="graph_type" @change="$forceUpdate()">
                    <v-radio :ripple="false" value="bar" label="Barras" />
                    <v-radio :ripple="false" value="area" label="Áreas" />
                    <v-radio :ripple="false" value="line" label="Lineas" />
                </v-radio-group>
                </div>
                <SimpleXYChart :series="value.grafico" :type="graph_type" :extra="graph_config" />
            </v-card>
        </v-col>
        <v-col cols="12">
            <v-card outlined>
                <v-data-table dense :headers="headers" :items="value.lista">
                    <template v-slot:item.cursos_rel="{ item }">
                        <ProgressCounter v-model="item.cursos_completados" :total="item.cursos_total" color="green" />
                    </template>
                </v-data-table>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import SimpleXYChart from '@/dash/components/SimpleXYChart'
import ProgressCounter from '@/dash/components/ProgressCounter'
import HorizontalCircularProgress from '@/shared/components/HorizontalCircularProgress'

export default {
    props: ['value', 'view'],
    components: {
        SimpleXYChart,
        ProgressCounter,
        HorizontalCircularProgress
    },
    data: () => ({
        graph_type: "bar",
        graph_config: {
            yaxis: {
                type: 'numeric',
                labels: {
                    formatter: (val) => Math.round(val * 100) + "%"
                }
            },
            tooltip: {
                type: Object,
                default: () => ({
                    y: {
                        formatter: (val) => Math.round(val * 100) + "%"
                    }
                })
            },
            colors: ['#4CAF50'],
            markers: {
                size: 5, 
                colors: ['#4CAF50']
            }
        },
        headers: [
            { value: "rut", text: "R.U.T" },
            { value: "first_name", text: "Nombres" },
            { value: "last_name", text: "Apellidos" },
            { value: "empresa", text: "Empresa" },
            { value: "gerencia", text: "Gerencia" },
            { value: "cursos_rel", text: "Progreso", width: "28%" }
        ]
    })
}
</script>