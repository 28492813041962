<template>
    <v-card outlined>
        <v-row no-gutters class="pa-0 ma-0">
            <v-col class="pa-2 flex-grow-0 flex-shrink-1 my-auto" align="center">
                <v-icon size="84" color="primary">account_circle</v-icon>
            </v-col>
            <v-col class="pa-2 flex-grow-1 flex-shrink-0 my-auto" >
                <p class="ma-0 subtitle-1">{{ value.rut }}</p>
                <p class="ma-0 text--primary headline font-weight-regular">{{ nombre }}</p>
                <p class="ma-0 subtitle-2">Empresa: {{ value.empresa.nombre }}</p>
                <p class="ma-0 subtitle-2">Gerencia: {{ value.gerencia.nombre }}</p>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
export default {
    props: ['value'],
    computed: {
        nombre() {
            if(!this.value) return "Perfil desconocido";
            return this.value.first_name + " " + this.value.last_name;
        },
        empresa() {
            if(!this.value || !this.value.empresa) return "Empresa desconocida";
            return this.value.empresa.nombre;
        },
        gerencia() {
            if(!this.value || !this.value.gerencia) return "Gerencia desconocida";
            return this.value.gerencia.nombre;
        }
    }
}
</script>