<template>
    <v-card flat>
        <v-row>
            <v-col cols="12" sm="6">
                <StatMainCard :sub="datos.target.tipo" :nombre="datos.target.nombre" :icon="datos.target.icon" color="secondary" />
            </v-col>
            <v-col cols="12" sm="6">
                <GrupoCard v-model="datos.grupo" :all="!datos.grupo" />
            </v-col>
        </v-row>
        <v-alert type="error" v-if="!!datos.error">{{ datos.error }}</v-alert>
        <div v-else>
            <v-item-group v-model="page" class="text-center mb-2" mandatory>
                <v-item v-for="n in page_length" :key="n" v-slot:default="{ active, toggle }">
                    <v-btn :input-value="active" icon @click="toggle">
                        <b>{{ n }}</b>
                    </v-btn>
                </v-item>
            </v-item-group>
            <v-window v-model="page">
                <v-window-item v-if="!!datos.evaluaciones" :key="1">
                    <SimpleCard title="Detalles generales">
                        <GenMain v-model="datos.detalles" :view="datos.view" />
                    </SimpleCard>
                </v-window-item>
                <v-window-item v-if="!!datos.evaluaciones" :key="2">
                    <SimpleCard title="Evaluaciones con más respuestas incorrectas">
                        <GenRanking v-model="datos.evaluaciones" :view="datos.view" />
                    </SimpleCard>
                </v-window-item>
                <v-window-item :key="3">
                    <SimpleCard title="Progreso por participantes">
                        <GenProgreso v-model="datos.progreso" :view="datos.view" />
                    </SimpleCard>
                </v-window-item>
                <v-window-item :key="4">
                    <SimpleCard title="Tiempo de respuesta">
                        <GenTiempo v-model="datos.tiempo" :view="datos.view" />
                    </SimpleCard>
                </v-window-item>
            </v-window>
        </div>
    </v-card>
</template>


<script>
import SimpleCard from '@/shared/components/SimpleCard'
import StatMainCard from './StatMainCard'
import GrupoCard from './GrupoCard'
import GenMain from './GenMain'
import GenProgreso from './GenProgreso'
import GenRanking from './GenRanking'
import GenTiempo from './GenTiempo'
import SimpleXYChart from '@/dash/components/SimpleXYChart'
import ProgressCounter from '@/dash/components/ProgressCounter'

export default {
    props: ['value', 'nombre', 'icon', 'view'],
    components: {
        GenMain,
        GenProgreso,
        GenRanking,
        GenTiempo,
        GrupoCard,
        SimpleCard,
        StatMainCard,
        SimpleXYChart,
        ProgressCounter
    },
    computed: {
        datos() {
            if(!this.value) {
                return {};
            }
            return this.value;
        }
    },
    data: () => ({
        page_length: 4,
        page: 0
    }),
    methods: {
        reset() {
            
        }
    }
}
</script>