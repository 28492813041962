<template>
    <v-card outlined>
        <v-row no-gutters class="pa-0 ma-0">
            <v-col class="pa-2 flex-grow-0 flex-shrink-1 my-auto" align="center">
                <v-icon size="84" :color="color">{{ icon }}</v-icon>
            </v-col>
            <v-col class="pa-2 flex-grow-1 flex-shrink-0 my-auto" >
                <p class="ma-0 subtitle-2">{{ sub }}</p>
                <p class="ma-0 text--primary headline font-weight-regular">{{ nombre }}</p>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
export default {
    props: ['sub', 'nombre', 'icon', 'color'],
}
</script>