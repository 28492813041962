<template>
    <v-row class="ma-n4">
        <v-col cols="12">
            <v-card outlined class="px-3 mx-1 mb-3">
                <HorizontalCircularProgress :color="get_color(value.score_general)" size="100" :value="value.score_general * 100">
                    <div>
                        <span class="d-block subtitle-2 font-weight-bold">Tiempo General</span>
                        <span class="d-block headline font-weight-regular">{{ value.tag_general }}</span>
                        <span class="d-block subtitle-2">Basado en los tiempos de respuesta de cada grupo de acceso</span>
                    </div>
                </HorizontalCircularProgress>
            </v-card>
        </v-col>
        <v-col cols="12">
            <v-card outlined>
                <div class="d-block text-right pa-2">
                <v-radio-group hide-details class="d-inline-block pa-0 ma-0" row v-model="graph_type" @change="$forceUpdate()">
                    <v-radio :ripple="false" value="bar" label="Barras" />
                    <v-radio :ripple="false" value="area" label="Áreas" />
                    <v-radio :ripple="false" value="line" label="Lineas" />
                </v-radio-group>
                </div>
                <SimpleXYChart :series="value.grafico" :type="graph_type" :extra="graph_config" />
            </v-card>
        </v-col>
        <v-col cols="12">
            <v-card outlined>
                <v-data-table dense :headers="headers" :items="value.lista">
                    <template v-slot:item.score="{ item }">
                        <v-chip label dark :color="get_color(item.score)">{{ item.tag }} ({{ item.score | decimal_percent }})</v-chip>
                    </template>
                </v-data-table>
            </v-card>
        </v-col>
    </v-row>
</template>


<script>
import HorizontalCircularProgress from '@/shared/components/HorizontalCircularProgress'
import ProgressCircle from '@/dash/components/ProgressCircle'
import ProgressCounter from '@/dash/components/ProgressCounter'
import SimpleXYChart from '@/dash/components/SimpleXYChart'
import EmptyCard from '@/shared/components/EmptyCard'
import moment from 'moment'

export default {
    props: ['value', 'view'],
    components: {
        HorizontalCircularProgress,
        ProgressCircle,
        ProgressCounter,
        SimpleXYChart,
        EmptyCard
    },
    computed: {
        logs() {
            return this.value.logs;
        }
    },
    data: () => ({
        grupo: null,
        graph_type: "bar",
        graph_config: {
            yaxis: {
                type: 'numeric',
                labels: {
                    formatter: (val) => Math.round(val * 100) + "%"
                }
            },
            tooltip: {
                type: Object,
                default: () => ({
                    y: {
                        formatter: (val) => Math.round(val * 100) + "%"
                    }
                })
            },
            markers: {
                size: 5
            }
        },
        headers: [
            { value: "rut", text: "R.U.T" },
            { value: "first_name", text: "Nombres" },
            { value: "last_name", text: "Apellidos" },
            { value: "empresa", text: "Empresa " },
            { value: "gerencia", text: "Gerencia" },
            { value: "score", text: "Tiempo de respuesta ", align: "center" }
        ]
    }),
    methods: {
        get_color(value) {
            if (value < .25)
                return "red darken-1"
            else if ((value >= .25) && (value <= .4))
                return "deep-orange"
            else if ((value > .4) && (value <= .725))
                return "light-green darken-1"
            else if (value > .725)
                return "green"
        }
    }
}
</script>