<template>
    <v-card flat>
        <v-row>
            <v-col cols="12" sm="6">
                <PersonaCard v-model="datos.persona" />
            </v-col>
            <v-col cols="12" sm="6">
                <GrupoCard v-model="datos.grupo" :all="!datos.grupo" />
            </v-col>
        </v-row>
        <v-alert type="error" v-if="!!datos.error">{{ datos.error }}</v-alert>
        <div v-else>
            <v-item-group v-model="page" class="text-center mb-2" mandatory>
                <v-item v-for="n in page_length" :key="n" v-slot:default="{ active, toggle }">
                    <v-btn :input-value="active" icon @click="toggle">
                        <b>{{ n }}</b>
                    </v-btn>
                </v-item>
            </v-item-group>
            <v-window v-model="page" @change="force_update">
                <v-window-item v-if="!!datos.evaluaciones" :key="1">
                    <SimpleCard title="Actividad en evaluaciones" body-class="pa-0">
                        <EvalActividad ref="general" v-model="datos.evaluaciones" />
                    </SimpleCard>
                </v-window-item>
                <v-window-item v-if="!!datos.rendimiento" :key="2">
                    <SimpleCard title="Rendimiento general">
                        <EvalRendimiento ref="rendimiento" v-model="datos.rendimiento" />
                    </SimpleCard>
                </v-window-item>
                <v-window-item v-if="!!datos.tiempo" :key="3">
                    <SimpleCard title="Tiempo de respuesta">
                        <EvalTiempo ref="tiempo" v-model="datos.tiempo" />
                    </SimpleCard>
                </v-window-item>
            </v-window>
        </div>
    </v-card>
</template>


<script>
import ProgressCircle from '@/dash/components/ProgressCircle'
import ProgressCounter from '@/dash/components/ProgressCounter'
import SimpleCard from '@/shared/components/SimpleCard'
import SimpleXYChart from '@/dash/components/SimpleXYChart'
import PersonaCard from './PersonaCard'
import EvalActividad from './EvalActividad'
import EvalRendimiento from './EvalRendimiento'
import EvalTiempo from './EvalTiempo'
import GrupoCard from './GrupoCard'
import moment from 'moment'

export default {
    props: ['value'],
    components: {
        EvalActividad,
        EvalRendimiento,
        EvalTiempo,
        PersonaCard,
        GrupoCard,
        ProgressCircle,
        ProgressCounter,
        SimpleCard,
        SimpleXYChart
    },
    data: () => ({
        page_length: 3,
        page: 0,
        rend_headers: [
            { value: "pregunta__qid", text: "" },
            { value: "pregunta__texto", text: "Pregunta" },
            { value: "intentos", text: "Cantidad de intentos", width: '20%' }
        ],
        graph_options: 
        {
            markers: {
                size: 5, 
                colors: ['#FF0000']
            }
        },
        selected_eval: null
    }),
    computed: {
        evaluaciones_aprobadas_rel() {
            if(!this.datos) return 0;
            if (this.datos.evaluaciones.total < 1) return 0;
            return (this.datos.evaluaciones.aprobadas / this.datos.evaluaciones.total) * 100;
        },
        evaluaciones_aprobadas() {
            return this.datos.evaluaciones.lista_aprobadas || [];
        },
        evaluaciones_all() {
            return this.datos.evaluaciones.lista_all || [];
        },
        rendimiento_graph() {
            if(!this.selected_eval) return [];

            return [{ name: "Intentos fallidos por Nº pregunta", data: this.selected_eval.respuestas.map(x => ({ x: "Preg. " + x.pregunta__qid,  y:  x.intentos})) }];
        },
        rendimiento_data() {
            if(!this.selected_eval) return [];
            return this.selected_eval.respuestas || [];
        },
        datos() {
            if(!this.value) {
                return {};
            }
            return this.value;
        }
    },
    methods: {
        force_update() {
            this.$forceUpdate();
        },
        reset() {
            this.selected_eval = null;
            if(!!this.$refs.rendimiento) {
                this.$refs.rendimiento.reset();
            }
            if(!!this.$refs.tiempo) {
                this.$refs.tiempo.reset();
            }
        },
        time_diff(log_item) {
            var end = moment(log_item.fecha_termino);
            var start = moment(log_item.fecha_inicio);

            return moment.utc(end.diff(start));
        }
    }
}
</script>