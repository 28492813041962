<template>
    <v-card outlined>
        <v-row no-gutters class="pa-0 ma-0" v-if="!all">
            <v-col class="px-2 flex-grow-0 flex-shrink-1 my-auto" align="center">
                <v-icon size="84" color="primary">group</v-icon>
            </v-col>
            <v-col class="px-2 flex-grow-1 flex-shrink-0 my-auto">
                <p class="ma-0 text--primary headline font-weight-regular">{{ nombre }}</p>
            </v-col>
            <v-col cols="12" class="pa-2">
                <p class="ma-0 subtitle-1"><v-icon left>person</v-icon>{{ evaluador }}</p>
                <p class="ma-0 subtitle-2"><v-icon left>event</v-icon>Desde {{ inicio | moment("L") }} hasta {{ termino | moment("L") }}</p>
                <p class="ma-0 subtitle-2" v-if="!!incidente">
                    <v-icon left>star</v-icon>{{ incidente }}
                </p>
            </v-col>
        </v-row>
        <v-row no-gutters class="pa-0 ma-0" v-else>
            <v-col class="pa-2 pt-2 flex-grow-0 flex-shrink-1 my-auto" align="center">
                <v-icon size="84" color="primary">group</v-icon>
            </v-col>
            <v-col class="px-4 py-6 flex-grow-1 flex-shrink-0 my-auto">
                <p class="ma-0 text--primary headline font-weight-regular">Todos los grupos de acceso a los cuales pertenece</p>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
export default {
    props: ['value', 'all'],
    computed: {
        nombre() {
            if(!this.value) return "Grupo sin nombre";
            return this.value.nombre;
        },
        inicio() {
            if(!this.value) return "...";
            return this.value.fecha_inicio;
        },
        termino() {
            if(!this.value) return "...";
            return this.value.fecha_termino;
        },
        evaluador() {
            if(!this.value) return "Evaluador desconocido";
            return this.value.evaluador;
        },
        incidente() {
            if(!this.value || !this.value.ref_incidente) return null;
            return `Cod. ${this.value.ref_incidente.cod_incidente} - ${this.value.ref_incidente.titulo}`;
        }
    }
}
</script>