
<template>
    <v-row>
        <v-col cols="12">
            <v-label>Tipo de estadística</v-label>
            <v-radio-group class="ma-0" v-model="payload.stat_type" hide-details @change="reset">
                <template v-for="(itype, i) in stats_types">
                    <v-radio :key="i" :value="itype.value" :label="itype.text" />
                </template>
            </v-radio-group>
        </v-col>
        <v-col cols="12">
            <v-label>Grupo de acceso</v-label>
            <v-autocomplete clearable v-model="payload.grupo" solo outlined flat dense hide-details prepend-inner-icon="group" placeholder="Grupo de acceso"
                :items="grupos" 
                :item-text="(i) => `${i.nombre} (${i.fecha_inicio} - ${i.fecha_termino})`" 
                item-value="id" 
                @change="() => payload.curso = null" />
        </v-col>
        <v-col cols="12" v-if="!!payload.grupo">
            <v-label>Curso</v-label>
            <v-autocomplete v-model="payload.curso" solo outlined flat dense hide-details prepend-inner-icon="folder" placeholder="Curso" 
                :items="cursos" 
                item-text="nombre"
                item-value="id" />
        </v-col>
        <v-col cols="12" v-if="payload.stat_type == 1">
            <v-label>Consultar por R.U.T</v-label>
            <v-text-field hide-details solo flat dense outlined clearable v-model="payload.rut" prepend-inner-icon="account_circle" placeholder="12.345.678-K">
                <template v-slot:append-outer>
                    <v-btn depressed large color="primary" dark class="subtitle-2 my-n2" @click="do_submit">
                        <v-icon>search</v-icon>
                    </v-btn>
                </template>
            </v-text-field> 
        </v-col>
        <v-col cols="12" v-if="payload.stat_type == 2">
            <v-label>Seleccione una gerencia</v-label>
            <v-autocomplete hide-details solo flat dense outlined clearable v-model="payload.gerencia" :items="gerencias" prepend-inner-icon="account_box" placeholder="Gerencia" item-text="nombre" item-value="id" @input="do_submit">
                <template v-slot:append-outer>
                    <v-btn depressed large color="primary" dark class="subtitle-2 my-n2" @click="do_submit" type="submit">
                        <v-icon>search</v-icon>
                    </v-btn>
                </template>
            </v-autocomplete>
            <span class="my-4 d-block" />
            <v-label>Se considerarán solo participantes de la gerencia seleccionada.</v-label>
        </v-col>
        <v-col cols="12" v-if="payload.stat_type == 3">
            <v-label>Seleccione una empresa</v-label>
            <v-autocomplete hide-details solo flat dense outlined clearable v-model="payload.empresa" :items="empresas" prepend-inner-icon="business" placeholder="Empresa" item-text="nombre" item-value="id" @input="do_submit">
                <template v-slot:append-outer>
                    <v-btn depressed large color="primary" dark class="subtitle-2 my-n2" @click="do_submit" type="submit">
                        <v-icon>search</v-icon>
                    </v-btn>
                </template>
            </v-autocomplete>
            <span class="my-4 d-block" />
            <v-label>Se considerarán solo participantes de la empresa seleccionada.</v-label>
        </v-col>
    </v-row>
</template>


<script>
export default {
    props: ['value', 'grupos', 'cursos', 'empresas', 'gerencias'],
    data: () => ({
        stats_types: [
            { value: 1, text: "Persona Individual" },
            { value: 2, text: "Por Gerencia" },
            { value: 3, text: "Por Empresa" }
        ]
    }),
    computed: {
        payload: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    },
    methods: {
        reset() {
            if(!!this.payload.rut) delete this.payload.rut;
            if(!!this.payload.empresa) delete this.payload.empresa;
            if(!!this.payload.gerencia) delete this.payload.gerencia;
            this.$emit('reset');
        },
        do_submit() {
            this.$emit('submit', this.payload.stat_type);
        }
    }
}
</script>