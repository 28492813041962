<template>
    <v-row>
        <v-col cols="12" sm="12" md="4">
            <DashCard title="Controles" title-icon="settings">
                <StatsControls v-model="stats_payload" 
                    :cursos="cursos"
                    :grupos="index.grupos"
                    :gerencias="index.gerencias"
                    :empresas="index.empresas" 
                    @submit="submit_payload" 
                    @reset="reset_payload" />
            </DashCard>
        </v-col>
        <v-col cols="12" sm="12" md="8">
            <DashCard title="Resultados">
                <EmptyCard v-if="error.visible" :text="error.text" :icon="error.icon" />
                <v-card flat class="ma-0" v-else>
                    <template v-if="stats_payload.stat_type == 1">
                        <StatsPersona ref="sts_pa" v-model="stats_results" />
                    </template>
                    <template v-if="stats_payload.stat_type == 2 || stats_payload.stat_type == 3">
                        <StatsGeneral ref="sts_ge" v-model="stats_results" />
                    </template>
                </v-card>
            </DashCard>
        </v-col>
    </v-row>
</template>

<script>
import StatsControls from '@/educap/components/stats/StatsControls'
import StatsPersona from '@/educap/components/stats/StatsPersona'
import StatsGeneral from '@/educap/components/stats/StatsGeneral'
import EmptyCard from '@/shared/components/EmptyCard'
import DashCard from '@/shared/components/DashCard'
import { axiosHelper, messages } from '@/helpers'

export default {
    components: {
        EmptyCard,
        DashCard,
        StatsControls,
        StatsPersona,
        StatsGeneral
    },
    data: () => ({
        stats_payload: {
            stat_type: 1
        },
        stats_results: {},
        index: {},
        error: {
            visible: true,
            icon: "priority_high",
            text: "Sin datos que mostrar"
        }
    }),
    computed: {
        cursos() {
            if(!this.stats_payload.grupo) return [];
            var cursos = this.index.grupos.filter(x => x.id == this.stats_payload.grupo)[0].cursos;
            var all = { id: null, nombre: "Todos los cursos" };

            return [all, ...cursos];
        }
    },
    methods: {
        reset_payload() {
            this.stats_results = {};
            this.error.visible = true;
            this.error.icon = "priority_high";
            this.error.text = "Sin datos que mostrar";
        },
        submit_payload(stat_type) {
            switch(stat_type) {
                case 1: 
                    this.ask_persona();
                    break;
                case 2: 
                    this.ask_gerencia();
                    break;
                case 3: 
                    this.ask_empresa();
                    break;
            }
        },
        ask_persona() {
            if(!!this.$refs.sts_pa) {
                this.$refs.sts_pa.reset();
            }
            axiosHelper.post('edu/stats/type/?d=persona', this.stats_payload)
                .then(response => this.update_results(response.data))
                .catch(error => this.show_error(error.response));
        },
        ask_empresa() {
            if(!!this.$refs.sts_ge) {
                this.$refs.sts_ge.reset();
            }
            axiosHelper.post('edu/stats/type/?d=empresa', this.stats_payload)
                .then(response => this.update_results(response.data))
                .catch(error => this.show_error(error.response));
        },
        ask_gerencia() {
            if(!!this.$refs.sts_ge) {
                this.$refs.sts_ge.reset();
            }
            axiosHelper.post('edu/stats/type/?d=gerencia', this.stats_payload)
                .then(response => this.update_results(response.data))
                .catch(error => this.show_error(error.response));
        },
        update_results(data) {
            this.error.visible = false;
            this.stats_results = data;
        },
        show_error(response) {
            this.error.visible = true;

            var has_msg = (response.status == 400 || response.status == 404);

            this.error.icon = has_msg ? "error_outline" : "close";
            this.error.text = has_msg ? response.data.detail : "Hubo un error al obtener los datos, intente nuevamente";
        }
    },
    mounted() {
        this.$parent.$parent.set_title("Estadísticas por tipo", "insert_chart");
        axiosHelper.get('edu/stats/type/')
            .then(response => this.index = response.data);
    }
}
</script>