<template>
    <div>
        <v-row no-gutters class="ma-0">
            <v-col cols="12" sm="6">
                <v-card outlined class="px-3 mx-1 mb-3">
                    <HorizontalCircularProgress :color="get_color(value.score_general)" size="100" :value="value.score_general * 100">
                        <div>
                            <span class="d-block subtitle-2 font-weight-bold">Tiempo General</span>
                            <span class="d-block headline font-weight-regular">{{ value.tag_general }}</span>
                            <span class="d-block subtitle-2">Basado en los tiempos de respuesta de cada grupo de acceso</span>
                        </div>
                    </HorizontalCircularProgress>
                </v-card>
            </v-col>
            <v-col cols="12" sm="6">
                <v-card flat class="pa-3">
                    <v-label>Seleccione grupo de acceso</v-label>
                    <v-select 
                        clearable dense flat solo outlined hide-details 
                        prepend-inner-icon="group" label="Grupo de acceso"
                        item-key="grupo.id"
                        item-text="grupo.nombre"
                        v-model="grupo" :items="logs" return-object />
                </v-card>
            </v-col>
        </v-row>
        <v-divider class="my-4" />
        <v-row no-gutters class="ma-0">
            <v-col cols="12" sm="6" v-if="!!grupo">
                <v-card outlined class="pa-4 mx-1 mb-3">
                    <div>
                        <span class="d-block headline font-weight-regular">{{ grupo.grupo.nombre }}</span>
                        <span class="d-block subtitle-1"><v-icon left>event</v-icon>{{ grupo.grupo.fecha_inicio | moment("L") }} hasta {{ grupo.grupo.fecha_termino | moment("L") }}</span>
                        <span class="d-block subtitle-1"><v-icon left>folder</v-icon>{{ grupo.puntajes.length }} cursos rendidos</span>
                    </div>
                </v-card>
            </v-col>
            <v-col cols="12" sm="6" v-if="!!grupo">
                <v-card outlined class="px-3 mx-1 mb-3">
                    <HorizontalCircularProgress :color="get_color(grupo.score_general)" size="100" :value="grupo.score_general * 100">
                        <div>
                            <span class="d-block subtitle-2 font-weight-bold">Tiempo promedio del grupo</span>
                            <span class="d-block headline font-weight-regular">{{ grupo.tag_general }}</span>
                            <span class="d-block subtitle-2">Basado en los tiempos de respuesta de cada curso asignado</span>
                        </div>
                    </HorizontalCircularProgress>
                </v-card>
            </v-col>
            <v-col cols="12">
                <v-card outlined v-if="!!grupo">
                    <v-data-table dense :headers="headers" :items="grupo.puntajes">
                        <template v-slot:item.curso="{ item }">
                            <v-icon left>folder</v-icon>{{ item.curso }}
                        </template>
                        <template v-slot:item.finalizado="{ item }">
                            <v-icon left color="green">check</v-icon>{{ item.finalizado | moment("L HH:mm") }}
                        </template>
                        <template v-slot:item.score="{ item }">
                            <v-chip class="my-1" label dark :color="get_color(item.score)">{{ item.tag }} ({{ item.score | decimal_percent }})</v-chip>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
            <v-col cols="12">
                <v-card outlined v-if="!grupo">
                    <v-data-table dense :headers="gen_headers" :items="logs">
                        <template v-slot:item.grupo="{ item }">
                            <v-icon left>group</v-icon>{{ item.grupo }}
                        </template>
                        <template v-slot:item.inicio="{ item }">
                            {{ item.grupo.fecha_inicio | moment("L") }}
                        </template>
                        <template v-slot:item.termino="{ item }">
                            {{ item.grupo.fecha_termino | moment("L") }}
                        </template>
                        <template v-slot:item.score="{ item }">
                            <v-chip class="my-2" label dark :color="get_color(item.score_general)">{{ item.tag_general }} ({{ item.score_general | decimal_percent }})</v-chip>
                        </template>
                        <template v-slot:item.grupo="{ item }">
                            <v-icon left>group</v-icon>{{ item.grupo.nombre }}<br/>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>


<script>
import HorizontalCircularProgress from '@/shared/components/HorizontalCircularProgress'
import ProgressCircle from '@/dash/components/ProgressCircle'
import ProgressCounter from '@/dash/components/ProgressCounter'
import SimpleXYChart from '@/dash/components/SimpleXYChart'
import EmptyCard from '@/shared/components/EmptyCard'
import moment from 'moment'

export default {
    props: ['value'],
    components: {
        HorizontalCircularProgress,
        ProgressCircle,
        ProgressCounter,
        SimpleXYChart,
        EmptyCard
    },
    computed: {
        logs() {
            return this.value.logs;
        }
    },
    data: () => ({
        grupo: null,
        headers: [
            { value: "curso", text: "Curso ", width: "30%", sortable: false },
            { value: "finalizado", text: "Finalizado ", width: "10%", align: "center" },
            { value: "score", text: "Tiempo de respuesta ", width: "10%", align: "center" }
        ],
        gen_headers: [
            { value: "grupo", text: "Grupo de acceso ", sortable: false },
            { value: "inicio", text: "Inicio " },
            { value: "termino", text: "Termino " },
            { value: "score", text: "Tiempo de respuesta ", align: "center" }
        ]
    }),
    methods: {
        get_color(value) {
            if (value < .25)
                return "red darken-1"
            else if ((value >= .25) && (value <= .4))
                return "deep-orange"
            else if ((value > .4) && (value <= .725))
                return "light-green darken-1"
            else if (value > .725)
                return "green"
        },
        reset() {
            this.grupo = null;
        }
    }
}
</script>