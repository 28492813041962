<template>
    <v-row no-gutters class="pa-0 ma-0">
        <v-col cols="12">
            <v-card outlined>
                <span class="d-block pa-2 font-weight-regular title text-center">Grupos con participantes que pertenecen {{ view == 'gerencia' ? "a la gerencia" : "a la empresa" }}</span>
                <v-divider/>
                <v-data-table dense :headers="headers" :items="value.grupos">
                    <template v-slot:item.fecha_inicio="{ item }">
                        {{ item.fecha_inicio | moment("L") }}
                    </template>
                    <template v-slot:item.fecha_termino="{ item }">
                        {{ item.fecha_termino | moment("L") }}
                    </template>
                    <template v-slot:item.evaluador="{ item }">
                        <v-chip dark color="indigo"><v-icon left>account_circle</v-icon>{{ item.evaluador }}</v-chip>
                    </template>
                </v-data-table>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import HorizontalCircularProgress from '@/shared/components/HorizontalCircularProgress'

export default {
    props: ['value', 'view'],
    components: {
        HorizontalCircularProgress
    },
    data: () => ({
        headers: [
            { value: "nombre", text: "Grupo" },
            { value: "evaluador", text: "Evaluador" },
            { value: "fecha_inicio", text: "Inicio" },
            { value: "fecha_termino", text: "Termino" }
        ]
    })
}
</script>